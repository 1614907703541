
import { Vue, Component, Prop } from 'vue-property-decorator';
import { IUser, IUserSkill } from '@/models/user';
import Icon from '@/components/ui/icon/Icon.vue';
import PopupDialogDefault from '../ui/popup-dialog/PopupDialogDefault.vue';
import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue';
import ToggleSwitch from '@/components/ui/toggle-switch/ToggleSwitch.vue';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import UsersModule from '@/store/modules/users/UsersModule';
import ApiClient from '@/api/api.client';
import Notify from '@/services/helpers/notify';
import GeneralModule from '@/store/modules/general/GeneralModule';
import TeamsModule from '@/store/modules/teams/TeamsModule';

@Component({
  name: 'UserCard',
  components: 
  {
    Icon,
    ToggleSwitch,
    PopupDialogDefault,
    ContextMenuSpawner,
    ContextMenuLink,
    DeleteContext,
  },
})
export default class UserCard extends Vue {
  @Prop({ required: true }) user: IUser;

  public passwordContextMenu = false;
  public showDeleteContext = false;
  public isUserAdminFake = true;
  public isUserAdmin = false;
  getBageStyles(skill: IUserSkill) {
    if (!skill.bageColor) return {};
    return {
      backgroundColor: skill.bageColor,
    };
  }
  get userHasAdminSpecialisations()
  {
    return Boolean(this.user.specialisations.filter((el) => el.is_admin).length)
  }
  get owner()
  {
    return UsersModule.ownerUser;
  }
  changeUserAdmin() 
  {
      if (UsersModule.currentUser.admin) 
      {
          ApiClient.user.makeAdmin(this.user.id).then(() => 
          {
              Notify.success('Роль пользователя изменена');
          });
      } else 
      {
          Notify.error('Вы не являетесь администратором');
      }
  }
  itemClicked(item: string)
  {
    if(item === 'changePassword')
    {
      this.passwordContextMenu = !this.passwordContextMenu
    }
    if(item === 'admin')
    {
      this.isUserAdmin = !this.isUserAdmin;      
      this.changeUserAdmin();
    }
    if(item === 'edit')
    {
      this.$router.push(`/team/user/${this.user.id}/edit`)
    }
    if(item === 'delete')
    {
      this.showDeleteContext = true;
    }
  }
  mounted()
  {
    this.isUserAdmin = this.user.admin || this.userHasAdminSpecialisations
  }
  deleteUser() 
  {   
      if (UsersModule.currentUser.admin) {
          this.showDeleteContext = false;
          ApiClient.user.deleteUser(this.user.id).then(() => {
              const ref = this.$refs.contextMenuTeam as ContextMenuSpawner
              ref?.close();
              Notify.success('Пользователь удален');
          });
          this.updateUsers(this.user.id)
      } else {
          Notify.error('Вы не являетесь администратором');
      }
  }
  updateUsers(userId: number)
  { 
    const filteredTeams = TeamsModule.teams.filter((el) => el.id !== userId);
     
    TeamsModule.setTeams(TeamsModule.teams.filter((el) => el.id !== userId))
  }
  changePassword(newPassword: string) 
  {
      if(this.user.id === UsersModule.currentUser.id || UsersModule.currentUser.admin)
      {
        ApiClient.teams.changePassword(this.user.id, newPassword).then(() => 
        {
          this.passwordContextMenu = false;
          if(this.user.id === UsersModule.currentUser.id)
          {
            GeneralModule.logout();
          }
          Notify.success('Пароль изменен');
        });
      }
      else
      {
        Notify.error('Вы не являетесь администратором');
      }  
  }
  get avatar() 
  {
    return this.user.avatar ? this.user.avatar : '/img/avatar.png';
  }
  get currentUser()
  {
    return UsersModule.currentUser
  }
  get fullName()
  {
    return `${this.user.first_name} ${this.user.last_name}`; 
  }
  onClick() {
    this.$router.push(`/team/user/${this.user.id}`);
  }
}


import ButtonLike, { ButtonType } from '@/mixins/ButtonLike';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import Icon from '../icon/Icon.vue';

@Component({
  components: {
    Icon,
  },
})
export default class Tag extends mixins(ButtonLike) {
  baseClass = 'tag';

  @Prop({ default: 'outline' }) type: ButtonType;
  @Prop({ default: 'light-100' }) color: string;
}
